button {
  outline: none;
}

.btn {
  --fz: 16;
  --button-text-color: var(--z-white);
  --button-bg: var(--primary-gradient);
  --button-border: var(--primary);

  &--secondary {
    --button-text-color: var(--z-white);
    --button-bg: transparent;
    --button-border: var(--secondary);
  }

  @media (min-width: 960px) {
    --fz: 18;
  }
}

.btn {
  font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
  color: var(--button-text-color);
  outline: none;
  cursor: pointer;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  border-radius: calc(var(--border-radius) / var(--fz) * var(--fz-em));
  display: inline-block;
  border: 1px solid var(--button-border);
  background: var(--button-bg);
  padding: calc(14 / var(--fz) * var(--fz-em)) calc(16 / var(--fz) * var(--fz-em));
  transition: 0.2s linear;
  margin: 0;

  &:hover,
  &:focus {
    color: var(--button-text-color);
    text-decoration: none;
    background-color: var(--button-bg);
  }

  &.btn--is-disabled {
    background: var(--button-bg);

    &:hover,
    &:focus {
      background: var(--button-bg);
    }
  }

  &--icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;

    span {
      flex-shrink: 0;
    }

    span+span {
      margin-left: calc(10 / var(--fz) * var(--fz-em));
    }

    .icon {
      height: calc(20 / var(--fz) * var(--fz-em));
    }
  }

  &--is-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}