:root {
	--fz-px: 1px;
	--fz-em: 1em;
	--fz-base: 16;
	--fz: var(--fz-base);
	--font: 'Roboto', sans-serif;
	--font-title: 'Montserrat', sans-serif;

	@media (min-width: 960px) {
		--fz-base: 16;
	}

	@media (min-width: 1280px) {
		--fz-base: 18;
	}

	@media (min-width: 1480px) {
		--fz-base: 20;
	}
}

:root {
	--z-white: hsl(0, 0%, 100%);
	--z-gray-90: hsl(0, 0%, 90%);
	--z-gray-40: hsl(0, 0%, 40%);
	--z-black: hsl(0, 0%, 0%);

	--z-dark-cornflower-blue: hsl(213, 100%, 22%);
	--z-dark-cornflower-blue-lighten: hsl(213, 100%, 32%);

	--z-carnelian: hsl(355, 98%, 36%);
	--z-carnelian-lighten: hsl(355, 98%, 46%);

	--z-cinerious: hsl(14, 17%, 46%);

	--z-cotton-seed: hsl(48, 3%, 70%);
	--z-mercury: hsl(11, 8%, 93%);
	--z-cloud: hsl(24, 5%, 79%);

	--z-tide: hsl(26, 5%, 72%);
}

:root {
	// common colors
	--primary: var(--z-dark-cornflower-blue);
	--primary-light: var(--z-dark-cornflower-blue-lighten);
	--secondary: var(--z-carnelian);

	--primary-gradient: linear-gradient(93deg, var(--z-dark-cornflower-blue), var(--z-dark-cornflower-blue-lighten));

	--header-h1-color: var(--z-cinerious);
	--header-color: var(--z-gray-40);
	--header-color-shadow: var(--z-gray-90);

	--text-color: var(--z-gray-40);
	--link-color: var(--z-cotton-seed);

	--text-color-highlighted: var(--z-dark-cornflower-blue-lighten);
	--text-color-highlighted-lighten: var(--z-dark-cornflower-blue);

	--text-color-highlighted-secondary: var(--z-carnelian-lighten);
	--text-color-highlighted-secondary-lighten: var(--z-carnelian);

	--icon-color: var(--z-cinerious);

	--main-bg: var(--z-white);

	--header-bg: var(--z-white);
	--header-border-color: var(--z-cloud);

	--modal-bg: var(--z-white);
	--bg-offset-canvas: var(--z-white);
	--bg-offset-canvas-overlay: hsla(0, 0%, 0%, 0.2);
	--bg-offset-canvas-mobile: var(--z-white);

	--scrollbar-bg: var(--z-cinerious);

	--list-marker-color: currentColor;
	--totop-color: var(--z-white);
	--totop-bg: var(--primary);
	--burger-color: var(--text-color);
}

:root {
	--border-radius: 4;
	--header-height: 60;

	@media (min-width: 1140px) {
		--header-height: 90;
	}
}