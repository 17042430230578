.icon {
	width: 1em;
	height: 1em;
	fill: currentColor;
	display: inline-block;
}

.icon-email {
	width:(41/31)*1em;
}
.icon-map {
	width:(34/47)*1em;
}
.icon-phone {
	width:(43/43)*1em;
}
