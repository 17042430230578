@charset "UTF-8";
:root {
  --fz-px: 1px;
  --fz-em: 1em;
  --fz-base: 16;
  --fz: var(--fz-base);
  --font: 'Roboto', sans-serif;
  --font-title: 'Montserrat', sans-serif; }
  @media (min-width: 960px) {
    :root {
      --fz-base: 16; } }
  @media (min-width: 1280px) {
    :root {
      --fz-base: 18; } }
  @media (min-width: 1480px) {
    :root {
      --fz-base: 20; } }

:root {
  --z-white: hsl(0, 0%, 100%);
  --z-gray-90: hsl(0, 0%, 90%);
  --z-gray-40: hsl(0, 0%, 40%);
  --z-black: hsl(0, 0%, 0%);
  --z-dark-cornflower-blue: hsl(213, 100%, 22%);
  --z-dark-cornflower-blue-lighten: hsl(213, 100%, 32%);
  --z-carnelian: hsl(355, 98%, 36%);
  --z-carnelian-lighten: hsl(355, 98%, 46%);
  --z-cinerious: hsl(14, 17%, 46%);
  --z-cotton-seed: hsl(48, 3%, 70%);
  --z-mercury: hsl(11, 8%, 93%);
  --z-cloud: hsl(24, 5%, 79%);
  --z-tide: hsl(26, 5%, 72%); }

:root {
  --primary: var(--z-dark-cornflower-blue);
  --primary-light: var(--z-dark-cornflower-blue-lighten);
  --secondary: var(--z-carnelian);
  --primary-gradient: linear-gradient(93deg, var(--z-dark-cornflower-blue), var(--z-dark-cornflower-blue-lighten));
  --header-h1-color: var(--z-cinerious);
  --header-color: var(--z-gray-40);
  --header-color-shadow: var(--z-gray-90);
  --text-color: var(--z-gray-40);
  --link-color: var(--z-cotton-seed);
  --text-color-highlighted: var(--z-dark-cornflower-blue-lighten);
  --text-color-highlighted-lighten: var(--z-dark-cornflower-blue);
  --text-color-highlighted-secondary: var(--z-carnelian-lighten);
  --text-color-highlighted-secondary-lighten: var(--z-carnelian);
  --icon-color: var(--z-cinerious);
  --main-bg: var(--z-white);
  --header-bg: var(--z-white);
  --header-border-color: var(--z-cloud);
  --modal-bg: var(--z-white);
  --bg-offset-canvas: var(--z-white);
  --bg-offset-canvas-overlay: hsla(0, 0%, 0%, 0.2);
  --bg-offset-canvas-mobile: var(--z-white);
  --scrollbar-bg: var(--z-cinerious);
  --list-marker-color: currentColor;
  --totop-color: var(--z-white);
  --totop-bg: var(--primary);
  --burger-color: var(--text-color); }

:root {
  --border-radius: 4;
  --header-height: 60; }
  @media (min-width: 1140px) {
    :root {
      --header-height: 90; } }

/* custom-reset.css | 27.08.2018 | https://yurch-html.github.io/dist/custom-reset.html */
*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  box-sizing: border-box;
  height: 100%; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html {
    display: flex;
    flex-direction: column; } }

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  line-height: 1;
  text-rendering: optimizeLegibility;
  text-decoration-skip: objects;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent; }

p,
dd,
dl,
figure,
blockquote {
  margin: 0; }

blockquote,
q {
  quotes: none; }

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit; }

audio,
video {
  display: block; }

img {
  display: block;
  border: none; }

iframe {
  border: none; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: inherit; }

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit; }

abbr {
  border: none;
  text-decoration: none; }

b,
strong {
  font-weight: inherit; }

i,
em {
  font-style: inherit; }

dfn {
  font-style: inherit; }

mark {
  background-color: transparent;
  color: inherit; }

small {
  font-size: inherit; }

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: inherit;
  line-height: 0; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  font: inherit;
  color: inherit;
  letter-spacing: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-align: left;
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
  -webkit-appearance: none; }

textarea {
  resize: none;
  overflow-y: auto;
  overflow-x: hidden; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border: none;
  padding: 0; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: none;
  font: inherit; }

fieldset {
  padding: 0;
  margin: 0;
  border: none; }

legend {
  display: block;
  padding: 0;
  white-space: normal; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

select::-ms-expand {
  display: none; }

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity .3s; }

::-moz-input-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity .3s; }

:-moz-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity .3s; }

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity .3s; }

:focus::-webkit-input-placeholder {
  opacity: 0; }

:focus::-moz-input-placeholder {
  opacity: 0; }

:focus:-moz-placeholder {
  opacity: 0; }

:focus:-ms-input-placeholder {
  opacity: 0; }

svg {
  display: block;
  width: 100%;
  height: 100%; }

[hidden] {
  display: none; }

:disabled,
.disabled {
  cursor: not-allowed; }

::-ms-clear {
  display: none; }

:-webkit-autofill {
  box-shadow: 0 0 100px #fff inset;
  -webkit-text-fill-color: currentColor; }

::selection {
  color: #fff;
  background-color: #43abf0; }

.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }

.cover-pic,
.contain-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.cover-pic {
  object-fit: cover; }

.contain-pic {
  object-fit: contain; }

.footer-page {
  margin-top: auto; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  display: inline-block; }

.icon-email {
  width: 1.32258em; }

.icon-map {
  width: 0.7234em; }

.icon-phone {
  width: 1em; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: local("Roboto Regular"), local("Roboto-Regular"), url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

html {
  height: 100%;
  font-size: calc(var(--fz-base) * var(--fz-px));
  line-height: 1.16; }

body {
  color: var(--text-color);
  font-family: var(--font-title);
  font-size: inherit;
  background: var(--main-bg);
  font-weight: 500; }

strong {
  font-weight: 600; }

.footer,
.header {
  flex: none;
  z-index: 1;
  position: relative; }

.content {
  z-index: 0;
  height: 100%;
  position: relative; }

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: calc(1230 / var(--fz) * var(--fz-em));
  padding-left: calc(15 / var(--fz) * var(--fz-em));
  padding-right: calc(15 / var(--fz) * var(--fz-em)); }
  .container--is-wide {
    max-width: calc(1648 / var(--fz) * var(--fz-em)); }
  @media (max-width: 1080px) {
    .container {
      max-width: calc(960 / var(--fz) * var(--fz-em)); } }

.page-wrapper {
  flex: 1 0 auto;
  overflow: hidden;
  position: relative; }

.logo__link {
  height: 100%;
  display: block; }

.logo__img {
  height: 100%;
  display: block;
  max-width: 100%; }

.uk-sticky-placeholder {
  flex-shrink: 0; }

.text-is-white {
  color: var(--z-white) !important; }

.text-is-highlighted {
  color: var(--text-color-highlighted);
  text-shadow: calc(3 / var(--fz) * var(--fz-em)) calc(2 / var(--fz) * var(--fz-em)) calc(2 / var(--fz) * var(--fz-em)) var(--text-color-highlighted-lighten); }

.text-is-highlighted-secondary {
  color: var(--text-color-highlighted-secondary);
  text-shadow: calc(3 / var(--fz) * var(--fz-em)) calc(2 / var(--fz) * var(--fz-em)) calc(2 / var(--fz) * var(--fz-em)) var(--text-color-highlighted-secondary-lighten); }

.highlight-header {
  color: var(--header-h1-color);
  text-shadow: calc(2 / var(--fz) * var(--fz-em)) calc(2 / var(--fz) * var(--fz-em)) calc(2 / var(--fz) * var(--fz-em)) var(--header-color-shadow); }

.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
.uk-heading-2xlarge,
.uk-heading-large,
.uk-heading-medium,
.uk-heading-small,
.uk-heading-xlarge,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-title); }

h1,
.uk-h1 {
  --fz: 32;
  font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
  font-weight: 500;
  line-height: 1.1;
  color: var(--header-h1-color);
  font-family: var(--font-title);
  text-shadow: calc(2 / var(--fz) * var(--fz-em)) calc(2 / var(--fz) * var(--fz-em)) calc(2 / var(--fz) * var(--fz-em)) var(--header-color-shadow); }
  @media (min-width: 960px) {
    h1,
    .uk-h1 {
      --fz: 40; } }

h2,
.uk-h2 {
  --fz: 24;
  font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
  font-weight: 500;
  color: var(--header-color);
  font-family: var(--font-title);
  text-shadow: calc(2 / var(--fz) * var(--fz-em)) calc(2 / var(--fz) * var(--fz-em)) calc(2 / var(--fz) * var(--fz-em)) var(--header-color-shadow); }
  @media (min-width: 960px) {
    h2,
    .uk-h2 {
      --fz: 32; } }

h3,
.uk-h3 {
  --fz: 20;
  font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
  font-weight: 500;
  color: var(--header-color); }
  @media (min-width: 960px) {
    h3,
    .uk-h3 {
      --fz: 24; } }

h4,
.uk-h4 {
  --fz: 20;
  font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
  font-weight: 600;
  color: var(--header-color); }

.uk-section-small {
  padding: calc(40 / var(--fz) * var(--fz-em)) 0; }

p {
  line-height: 1.2; }

p + p {
  margin-top: calc(20 / var(--fz) * var(--fz-em)); }

.lead {
  --fz: 16;
  font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em)); }
  @media (min-width: 640px) {
    .lead {
      --fz: 16; } }
  @media (min-width: 960px) {
    .lead {
      --fz: 18; } }
  @media (min-width: 1280px) {
    .lead {
      --fz: 20; } }

.adaptive-container {
  height: 0;
  padding-bottom: 66.7%;
  position: relative; }
  .adaptive-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.offcanvas-mobile-menu {
  top: calc(var(--header-height) / var(--fz) * var(--fz-em)); }

@media (min-width: 1024px) {
  #burger-nav {
    display: none !important; } }

.uk-offcanvas-bar {
  background: var(--bg-offset-canvas); }
  .offcanvas-mobile-menu .uk-offcanvas-bar {
    background: var(--bg-offset-canvas-mobile);
    border-right: 2px solid var(--header-border-color); }
  .uk-offcanvas-bar a {
    color: var(--text-color); }
    .uk-offcanvas-bar a:hover, .uk-offcanvas-bar a:focus {
      color: var(--primary); }

.uk-offcanvas-overlay::before {
  background: var(--bg-offset-canvas-overlay); }

.uk-pagination > * > :focus,
.uk-pagination > * > :hover,
.uk-pagination > .uk-active > * {
  color: var(--z-white); }

* + .uk-grid-margin,
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin {
  margin-top: calc(40 / var(--fz) * var(--fz-em)); }

* + .uk-grid-margin-small,
.uk-grid + .uk-grid-row-small,
.uk-grid + .uk-grid-small,
.uk-grid-row-small > .uk-grid-margin,
.uk-grid-small > .uk-grid-margin {
  margin-top: 24px; }

.uk-section {
  --gutter: 40;
  padding: calc(var(--gutter) / var(--fz) * var(--fz-em)) 0; }
  @media (min-width: 960px) {
    .uk-section {
      --gutter: 100; } }

.uk-section-large {
  --gutter: 40;
  padding: calc(var(--gutter) / var(--fz) * var(--fz-em)) 0; }
  @media (min-width: 960px) {
    .uk-section-large {
      --gutter: 140; } }

.uk-modal-dialog {
  border-radius: calc(var(--border-radius) / var(--fz) * var(--fz-em));
  padding: calc(40 / var(--fz) * var(--fz-em));
  background: var(--modal-bg); }
  .uk-modal-dialog h2 {
    margin-bottom: calc(20 / var(--fz) * var(--fz-em)); }
  .uk-modal-dialog .form {
    padding: 0; }
  .uk-modal-dialog .modal-close {
    position: absolute;
    top: calc(20 / var(--fz) * var(--fz-em));
    right: calc(20 / var(--fz) * var(--fz-em));
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='cancel' viewBox='0 0 256 256'%3E%3Cpath fill='%23ffffff' d='M137.051 128l75.475-75.475c2.5-2.5 2.5-6.551 0-9.051s-6.551-2.5-9.051 0L128 118.949 52.525 43.475c-2.5-2.5-6.551-2.5-9.051 0s-2.5 6.551 0 9.051L118.949 128l-75.475 75.475a6.399 6.399 0 0 0 4.525 10.926 6.38 6.38 0 0 0 4.525-1.875L128 137.051l75.475 75.475c1.25 1.25 2.888 1.875 4.525 1.875s3.275-.625 4.525-1.875c2.5-2.5 2.5-6.551 0-9.051L137.051 128z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: calc(20 / var(--fz) * var(--fz-em));
    height: calc(20 / var(--fz) * var(--fz-em)); }

a {
  outline: none; }

.link,
a.link {
  color: var(--link-color);
  font-weight: 600;
  transition: 0.2s;
  border-bottom: 1px solid transparent; }
  .link:hover, .link:focus,
  a.link:hover,
  a.link:focus {
    color: var(--text-color);
    text-decoration: none;
    border-color: currentColor; }

* + .list {
  margin-top: calc(20 / var(--fz) * var(--fz-em)); }

.list + * {
  margin-top: calc(40 / var(--fz) * var(--fz-em)); }

.list li {
  position: relative;
  padding-left: calc(20 / var(--fz) * var(--fz-em)); }

.list--ul li {
  line-height: 1.5; }
  .list--ul li + li {
    margin-top: calc(12 / var(--fz) * var(--fz-em)); }
    .key-indicator .list--ul li + li {
      margin-top: calc(6 / var(--fz) * var(--fz-em)); }
  .list--ul li::before {
    left: 0;
    top: 0.6em;
    content: '';
    width: 0.3em;
    height: 0.3em;
    position: absolute;
    background-color: var(--list-marker-color);
    border-radius: 50%; }

button {
  outline: none; }

.btn {
  --fz: 16;
  --button-text-color: var(--z-white);
  --button-bg: var(--primary-gradient);
  --button-border: var(--primary); }
  .btn--secondary {
    --button-text-color: var(--z-white);
    --button-bg: transparent;
    --button-border: var(--secondary); }
  @media (min-width: 960px) {
    .btn {
      --fz: 18; } }

.btn {
  font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
  color: var(--button-text-color);
  outline: none;
  cursor: pointer;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  border-radius: calc(var(--border-radius) / var(--fz) * var(--fz-em));
  display: inline-block;
  border: 1px solid var(--button-border);
  background: var(--button-bg);
  padding: calc(14 / var(--fz) * var(--fz-em)) calc(16 / var(--fz) * var(--fz-em));
  transition: 0.2s linear;
  margin: 0; }
  .btn:hover, .btn:focus {
    color: var(--button-text-color);
    text-decoration: none;
    background-color: var(--button-bg); }
  .btn.btn--is-disabled {
    background: var(--button-bg); }
    .btn.btn--is-disabled:hover, .btn.btn--is-disabled:focus {
      background: var(--button-bg); }
  .btn--icon {
    align-items: center;
    display: inline-flex;
    justify-content: center; }
    .btn--icon span {
      flex-shrink: 0; }
    .btn--icon span + span {
      margin-left: calc(10 / var(--fz) * var(--fz-em)); }
    .btn--icon .icon {
      height: calc(20 / var(--fz) * var(--fz-em)); }
  .btn--is-disabled {
    cursor: not-allowed;
    opacity: 0.5; }

.scrollbar,
.select2-results__options {
  overflow: auto;
  padding-right: 1em; }

/* полоса прокрутки (скроллбар) */
.scrollbar::-webkit-scrollbar,
.select2-results__options::-webkit-scrollbar {
  width: 3px;
  /* ширина для вертикального скролла */
  height: 3px;
  /* высота для горизонтального скролла */
  background-color: transparent; }

/* ползунок скроллбара */
.scrollbar::-webkit-scrollbar-thumb,
.select2-results__options::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-bg);
  border-radius: 1em; }

.scrollbar::-webkit-scrollbar-thumb:hover,
.select2-results__options::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-bg); }

/* Стрелки */
.scrollbar::-webkit-scrollbar-button:vertical:start:decrement,
.select2-results__options::-webkit-scrollbar-button:vertical:start:decrement {
  background-color: transparent; }

.scrollbar::-webkit-scrollbar-button:vertical:end:increment,
.select2-results__options::-webkit-scrollbar-button:vertical:end:increment {
  background-color: transparent; }

.scrollbar::-webkit-scrollbar-button:horizontal:start:decrement,
.select2-results__options::-webkit-scrollbar-button:horizontal:start:decrement {
  background-color: transparent; }

.scrollbar::-webkit-scrollbar-button:horizontal:end:increment,
.select2-results__options::-webkit-scrollbar-button:horizontal:end:increment {
  background-color: transparent; }

.scrollbar {
  scrollbar-color: var(--scrollbar-bg) transparent;
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */ }

.scrollbar-mobile-container .scrollbar {
  padding: calc(25 / var(--fz) * var(--fz-em)) calc(10 / var(--fz) * var(--fz-em));
  margin-top: calc(-25 / var(--fz) * var(--fz-em)); }
  @media (max-width: 959px) {
    .scrollbar-mobile-container .scrollbar {
      flex-wrap: nowrap; } }
  @media (min-width: 960px) {
    .scrollbar-mobile-container .scrollbar {
      overflow: inherit; } }
  @media (max-width: 959px) {
    .scrollbar-mobile-container .scrollbar.uk-grid {
      margin-left: 0; } }
  @media (max-width: 959px) {
    .scrollbar-mobile-container .scrollbar.uk-grid > *:first-child {
      padding-left: 0;
      width: calc(50% - 30px); } }
  @media (max-width: 639px) {
    .scrollbar-mobile-container .scrollbar.uk-grid > *:first-child {
      width: calc(90% - 30px); } }
  @media (max-width: 959px) {
    .scrollbar-mobile-container .scrollbar > div {
      flex-shrink: 0; } }
  @media (max-width: 639px) {
    .scrollbar-mobile-container .scrollbar > div {
      width: 90%; } }

@media (max-width: 959px) {
  .scrollbar-mobile-container .scrollbar.logo-center-list.uk-grid > *:first-child {
    padding-left: 0;
    width: calc(30% - 30px); } }

@media (max-width: 639px) {
  .scrollbar-mobile-container .scrollbar.logo-center-list.uk-grid > *:first-child {
    width: calc(40% - 30px); } }

@media (max-width: 959px) {
  .scrollbar-mobile-container .scrollbar.logo-center-list > div {
    width: 30%; } }

@media (max-width: 639px) {
  .scrollbar-mobile-container .scrollbar.logo-center-list > div {
    width: 40%; } }

@media (max-width: 959px) {
  .scrollbar-mobile-container .scrollbar.key-indicators.uk-grid > *:first-child {
    padding-left: 0;
    width: calc(40% - 15px); } }

@media (max-width: 639px) {
  .scrollbar-mobile-container .scrollbar.key-indicators.uk-grid > *:first-child {
    width: calc(50% - 15px); } }

@media (max-width: 480px) {
  .scrollbar-mobile-container .scrollbar.key-indicators.uk-grid > *:first-child {
    width: calc(70% - 15px); } }

@media (max-width: 959px) {
  .scrollbar-mobile-container .scrollbar.key-indicators > div {
    width: 40%; } }

@media (max-width: 639px) {
  .scrollbar-mobile-container .scrollbar.key-indicators > div {
    width: 50%; } }

@media (max-width: 480px) {
  .scrollbar-mobile-container .scrollbar.key-indicators > div {
    width: 70%; } }
